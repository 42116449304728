<!-- eslint-disable vue/no-v-html -->
<template>
  <NuxtLink
    class="flex h-11 items-center justify-center whitespace-nowrap rounded-xl px-12 text-md antialiased md:h-12"
    :class="buttonClasses"
    :to="url"
    ><slot></slot>
    <svg
      v-if="inlineSvg?.inner"
      class="shrink-0"
      :class="iconClasses"
      v-bind="inlineSvg?.attr"
      v-html="inlineSvg?.inner"
    ></svg>
  </NuxtLink>
</template>
<script setup lang="ts">
const props = defineProps({
  url: {
    type: String,
    default: '',
  },
  inlineSvg: {
    type: Object,
    default: () => {},
  },
  theme: {
    type: String,
    default: 'primary',
  },
});

const buttonClasses = computed(() =>
  props.theme === 'secondary' ? secondaryButtonClasses : primaryButtonClasses
);

const iconClasses = computed(() =>
  props.theme === 'secondary' ? secondaryIconClasses : primaryIconClasses
);

const primaryButtonClasses =
  'bg-gradient-to-b from-[#0061ff] via-[#0054dd] to-[#0054dd] text-white transition duration-150 ease-in-out hover:bg-gradient-to-b hover:from-[#0061ff] hover:via-[#0061ff] hover:to-[#0061ff]';
const secondaryButtonClasses =
  'bg-black/6 text-black disabled:bg-white hover:bg-black/12';
const primaryIconClasses = '[&_path]:fill-white noFillOpacity ml-2';
const secondaryIconClasses = 'ml-2';
</script>

<style>
/* Tailwind do not currently support fill-opacity */
.noFillOpacity path {
  fill-opacity: 90%;
}
</style>
